import { Box, Button } from "@mui/material";
import {
  createBrowserRouter,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { pageNotFound } from "../assets";
import Esign from "../esign/Esign";

const ProtectedRoute = ({ children }: any) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const success = params.get("success");
  const id = params.get("id");
  const signerIdentifier = params.get("signerIdentifier");
  const esp = params.get("esp");
  const errorMessage = params.get("errorMessage");

  if (success === "true" && id && signerIdentifier && esp) {
    return children;
  }

  if (success === "false" && id && errorMessage) {
    return children;
  }

  // Redirect to a NotFound or another appropriate route if conditions are not met
  return <Navigate to="/not-found" />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/esign" replace />,
    errorElement: <NotFound />,
  },
  {
    path: "/esign",
    element: (
      <ProtectedRoute>
        <Esign />
      </ProtectedRoute>
    ),
  },
  {
    path: "/not-found",
    element: <NotFound />,
  },
]);

function NotFound() {
  const navigate = useNavigate();
  return (
    <Box
      height={"100vh"}
      width={"100%"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img width={"50%"} src={pageNotFound} alt="./loading..." />
    </Box>
  );
}

export default router;
