import { Box, Divider, styled, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateStatus } from "../api/services/esign";
import { LoaderButton } from "../components/LoaderButton";
import failed from "./failed.json";
import Verified from "./verified.json";

function Query() {
  return new URLSearchParams(useLocation().search);
}

const SuccessScreen = () => {
  const [apiCalled, setApiCalled] = useState(false);
  const query = Query();
  const success = query.get("success");
  const queryClient = useQueryClient();
  let [searchParams] = useSearchParams();
  const searchParamsObj = Object.fromEntries(searchParams);
  const [params, setParams] = useState<any>();

  const { mutate: status, isLoading } = useMutation(updateStatus, {
    onSuccess: (res: any) => {
      setParams(res?.data?.params);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  useEffect(() => {
    if (!apiCalled) {
      status({ data: searchParamsObj });
    }
  }, []);

  const StyledDfAcJc = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  }));

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          marginX: "8%",
          minHeight: "75vh",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}>
          <Divider />
          <StyledDfAcJc>
            <Box
              maxWidth={"250px"}
              maxHeight={"250px"}
              marginBottom={-1}
              marginTop={"5vh"}>
              {success === "true" ? (
                <Lottie animationData={Verified} loop={false} autoplay />
              ) : (
                <Lottie animationData={failed} loop={false} autoplay />
              )}
            </Box>
            <Typography variant="h4">
              {success === "true" ? "Verified !" : "Verification Failed!"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                margin: "0 auto",
                paddingLeft: "20%",
                paddingRight: "20%",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}>
              <Typography>
                {success === "true" ? (
                  <>E-Sign Successful! 🎉 </>
                ) : (
                  <> E-Sign Failed! ❌</>
                )}
              </Typography>
              <Typography>
                {success === "true" ? (
                  <>
                    {" "}
                    Congratulations! Your eSign process has been completed
                    successfully. Your digital signature and eKYC verification
                    are now fully processed and validated. Thank you for your
                    cooperation!
                  </>
                ) : (
                  <>
                    We're sorry, but your eSign process could not be completed
                    successfully. Unfortunately, your digital signature and eKYC
                    verification did not pass the validation checks. Please
                    review the information provided and try again. If you
                    continue to experience issues, contact our support team for
                    assistance.
                  </>
                )}
              </Typography>
            </Typography>

            <Box marginLeft={"10px"}>
              <LoaderButton
                submitLoader={isLoading}
                width={"120px"}
                height={"40px"}
                onClick={() => {
                  localStorage.clear();
                  if (params?.userType === "USER") {
                    window.location.replace(
                      `${params?.url}/deal-process/deals/${params?.code}/documentationSignee`
                    );
                  } else {
                    window.location.replace(
                      `${params?.url}/deals/${params?.code}/documentationSignee`
                    );
                  }
                }}
                title={"Go Back"}
              />
            </Box>
          </StyledDfAcJc>
        </Box>
      </Box>
    </Box>
  );
};
export default SuccessScreen;
